<template>
  <el-container :class="classObj" class="ym-layout" style="flex-direction: column">
    <div v-if="device==='mobile'&&sidebar.opened" class="drawer-bg" @click="handleClickOutside" />
    <hearder
      position="fixed"
      showWelcome
      :userInfo="userInfo"
      :rightMenu='rightMenu'
      :logoOption='logoOption'
      @logoClick='logoClick'
      @headerImgClick='headerImgClick'
      @dropdownItemClick="dropdownItemClick"
      :menuButton="menuButton">
    </hearder>
    <el-container class="ym-body">
      <el-aside :class="menuButton">
        <ym-menu :routes="sidebarRouters" v-if='permissiveRoutes'></ym-menu>
        <ym-menu :routes="routers" v-else></ym-menu>
        <!-- 菜单isCollapse切换按钮 -->
        <div class="ym-menu-collapse hidden-sm-and-down">
          <menu-icon :is-active="sidebar.opened" @toggleClick="toggleSideBar" class="ym-menu-collapse-icon" />
        </div>
      </el-aside>
      <el-main>
        <breadcrumb :bottom="20" :useDefault="false"></breadcrumb>
        <transition name="fade-transverse" mode="out-in">
          <router-view class="ym-content" />
        </transition>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Hearder from './Header.vue'
import ymMenu from './Menu/index.vue'
import ResizeMixin from './mixin/ResizeHandler'
import MenuIcon from './Menu/MenuIcon'

export default {
  name: 'home',
  components: {
    Hearder,
    ymMenu,
    MenuIcon
  },
  mixins: [ResizeMixin],
  created() {
    this.permissiveRoutes = window.PERMISSIVE_ROUTES
  },
  data() {
    return {
      menuButton: 'left', // top left right 按钮位置
      routes: [],
      rightMenu: [
        {
          icon: 'el-icon-user',
          title: '个人中心',
          url: '/setting/personalCenter'
        },
        // {
        //   icon: 'el-icon-setting',
        //   title: '个人设置'
        // }
        {
          icon: 'el-icon-edit',
          title: '修改密码',
          url: '/setting/password'
        }
      ],
      logoOption: {
        logoType: 'img',
        title: '云博通链',
        url: require('./img/logo-header.png')
      },
      permissiveRoutes: true
    }
  },
  computed: {
    ...mapGetters(['sidebarRouters']),
    sidebar() {
      return this.$store.state.app.sidebar
    },
    device() {
      return this.$store.state.app.device
    },
    classObj() {
      return {
        hideSidebar: !this.sidebar.opened,
        openSidebar: this.sidebar.opened,
        withoutAnimation: this.sidebar.withoutAnimation,
        mobile: this.device === 'mobile'
      }
    },
    routers() {
      return this.$router.options.routes
    },
    logoOptions() {
      return Object.assign({
        overflow: true
      }, this.logoOption)
    },
    ...mapGetters(['userInfo', 'sidebar'])
  },
  methods: {
    handleClickOutside() {
      this.$store.dispatch('app/closeSideBar', { withoutAnimation: false })
    },
    logoClick() {
      console.log('点击logo')
    },
    ...mapActions(['logout']),
    headerImgClick() {
      console.log('点击头像')
    },
    // 下拉菜单点击
    dropdownItemClick(item) {
      if (item.url && this.$route.path !== item.url) {
        this.$router.push({ path: item.url })
      }
    },
    toggleSideBar() {
      this.$store.dispatch('app/toggleSideBar')
    }
  },
  filters: {}
}
</script>

<style lang="scss">
$header-height: $--header-height;
.ym-layout {
  height: 100%;
  overflow: hidden;
  .logo-container .logo-link .logo{
    height: 60px;
  }

  .ym-body {
    height: calc(100% - 60px);

    .ym-menu-collapse {
      position: fixed;
      z-index: 10;
      &-icon {
        width: 48px;
        height: 48px;
        line-height: 48px;
        cursor: pointer;
        transition: background .3s;
        -webkit-tap-highlight-color:transparent;
      }
    }
    .el-aside {
      transition: width 0.3s;
      text-align: left;
      background-color: $--aside-bg-color;
      width: $--aside-width !important;
      box-shadow: $--aside-shadow;
      -ms-overflow-style: none;
      overflow: -moz-scrollbars-none;
      &::-webkit-scrollbar {
        width: 0;
      }

      &.top .ym-menu-collapse {
        display: none;
      }
      &.left {
        margin-bottom: 47px;
        .ym-menu-collapse {
          left: 8px;
          bottom: 0px;
        }
      }
      &.right {
        .ym-menu-collapse {
          right: 20px;
          bottom: 30px;
          &-icon {
            background-color: #eee;
            border-radius: 50%;
            &:hover {
              background: rgba(0, 0, 0, .025)
            }
          }
        }
      }
    }

    .el-main {
      // padding: 30px 40px 40px 30px;
      padding: 30px 40px 0px 30px;
      overflow: auto;
      background-color: $--background-color-base;
      .ym-content {
        /*background-color: #fff;*/
        /*box-shadow: 0px 0px 27px 0px rgba(146, 146, 146, 0.28);*/
        /*border-radius: 8px;*/
      }
      &::after {
        content: '';
        display: block;
        height: 40px;
        visibility: hidden;
      }
    }
    // 手机版
    @media screen and (max-width: 768px) {
      .el-main {
        padding: 20px 0px;
      }
    }
  }
  &.mobile {
    .el-aside {
      height: 100%;
      position: fixed;
      font-size: 0px;
      top: 0;
      bottom: 0;
      left: 0;
      z-index: 1002;
    }
    .drawer-bg {
      background: #000;
      opacity: 0.3;
      width: 100%;
      top: 0;
      height: 100%;
      position: fixed;
      z-index: 1001;
    }
  }
  &.hideSidebar.mobile {
    .el-aside {
      width: 0 !important;
    }
  }
  &.hideSidebar {
    & .el-aside {
      width: 64px !important;
      transition: width 0.3s;
        & .el-menu-item.is-active::before {
        width: 0px;
      }
    }
    .el-menu--collapse .el-submenu > .el-submenu__title > span {
      height: 0;
      width: 0;
      overflow: hidden;
      visibility: hidden;
      display: inline-block;
    }
    .el-submenu > .el-submenu__title .el-submenu__icon-arrow {
      display: none;
    }
    .el-submenu [class^="el-icon-"] {
      position: absolute;
      font-size: $--font-size-medium;
      left: 20px;
      top: 20px;
    }
  }
}

</style>

import Vue from 'vue'

const state = {
  listInfo: []
}

const mutations = {
  INIT_LIST: (state, listName) => {
    state.listInfo.push({
      listName: listName,
      pageData: {
        currentPage: 1
      },
      search: {},
      tag: ''
    })
  },
  SET_LIST_PAGE: (state, payload) => {
    Vue.set(state.listInfo[payload.index].pageData, 'currentPage', payload.page)
  },
  SET_LIST_SEARCH: (state, payload) => {
    Vue.set(state.listInfo[payload.index], 'search', payload.search)
  }
}

const actions = {
  setListInfo({ commit }, listInfo) {
    let index = state.listInfo.findIndex(item => item.listName === listInfo.listName)
    console.log(index)
    if (index === -1) {
      commit('INIT_LIST', listInfo.listName)
      index = state.listInfo.length - 1
      console.log(index)
    }
    commit('SET_LIST_PAGE', { index: index, page: listInfo.pageData.currentPage })
    commit('SET_LIST_SEARCH', { index: index, search: listInfo.search })
  }
}

export default {
  namescaped: false,
  state,
  mutations,
  actions
}
